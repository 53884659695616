import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {useGlobalContext} from "./GlobalProvider";
import axios from "axios";
import {toast} from "react-toastify";

type Props = {
    children:  React.ReactNode
}

const AuthCheck: React.FC<Props> = ({ children }: Props) => {
    const navigate = useNavigate();
    const {token, setToken} = useGlobalContext();


    useEffect(() => {
        const checkAuth = async () => {
            if (!token) {
                navigate('/login');
            } else {
                try {
                    const headers = {headers: {Authorization: `Bearer ${token}`}}
                    const url = `${process.env.REACT_APP_BACK_URL}/test`;
                    await axios.get<[]>(url, headers);
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response?.status === 401) {
                        setToken(null);
                        navigate('/login');
                    } else {
                        console.error('An error occurred:', error);
                        const errorStatus =  axios.isAxiosError(error) ? (error.response?.status ?? error) : error;
                        toast.error("Сервер недоступен " + errorStatus);
                    }
                }
            }
        };

        checkAuth();
    }, [navigate, token, setToken]);

    return <>{children}</>;
};

export default AuthCheck;
