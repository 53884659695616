import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface GlobalContextType {
    inverted: boolean;
    setInverted: React.Dispatch<React.SetStateAction<boolean>>;
    token: null|string;
    setToken: React.Dispatch<React.SetStateAction<null|string>>;
    username: string;
    setUserName: React.Dispatch<React.SetStateAction<string>>;
}

// Создаем контекст с начальным значением
const GlobalContext = createContext<GlobalContextType>({
    inverted: false,
    setInverted: () => {},
    token: null,
    setToken: () => {},
    username: "",
    setUserName: () => {},
});

export const useGlobalContext = () => useContext(GlobalContext);

interface GlobalProviderProps {
    children: ReactNode;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
    const [inverted, setInverted] = useState<boolean>(localStorage.getItem('inverted') === 'true' ?? false);
    const [token, setToken] = useState<null|string>(localStorage.getItem('token'));
    const [username, setUserName] = useState<string>(localStorage.getItem('username') ?? '');


    useEffect(() => {
        localStorage.setItem('inverted', inverted ? 'true' : 'false');
    }, [inverted]);

    useEffect(() => {
        localStorage.setItem('username', username);
    }, [username]);

    useEffect(() => {
        if (token)
            localStorage.setItem('token', token);
        else
            localStorage.removeItem('token');
    }, [token]);

    return (
        <GlobalContext.Provider value={{ inverted, setInverted, token, setToken, username, setUserName }}>
            {children}
        </GlobalContext.Provider>
    );
};
