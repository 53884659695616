import React, {useEffect, useRef, useState} from 'react';
import {Table, Icon, Button} from 'semantic-ui-react';
import {GeneralAccount, KaspiMoySkladRepricer, KaspiMoySkladDopAccounts} from '../types';
import {useGlobalContext} from "./GlobalProvider";
import DatePickerModal from "./DatePickerModal";
import axios from "axios";
import {toast} from "react-toastify";
import {ToastOptions} from "react-toastify/dist/types";


interface AccountsTableProps {
    accountName: string | null;
    project: string | null;
    service: string | null;
    kaspiMoySkladRepricer: KaspiMoySkladRepricer[] | null;
    kaspiMoySkladDopAccounts: KaspiMoySkladDopAccounts[] | null;
    generalAccounts: GeneralAccount[] | null;
    setGeneralAccounts: React.Dispatch<React.SetStateAction<GeneralAccount[] | null>>;
    setKaspiMoySkladDopAccounts: React.Dispatch<React.SetStateAction<KaspiMoySkladDopAccounts[] | null>>;
    setKaspiMoySkladRepricer: React.Dispatch<React.SetStateAction<KaspiMoySkladRepricer[] | null>>;
    onChange?: () => void;
}


const emptyKaspiMoySkladDopAccounts: KaspiMoySkladDopAccounts = {
    id: null,
    date_deactivate: '',
    company_id: '',
    company_name: ''
}

const emptyKaspiMoySkladRepricer: KaspiMoySkladRepricer = {
    kaspi_tokens_id: null,
    company_id: '',
    company_name: '',
    phone: '',
    repricer_settings_id: null,
    product_limit: 0,
    data_end: ''
}

const emptyGeneralAccount: GeneralAccount = {
    id: null,
    date: '',
    phone: '',
    name: '',

}

const AccountsTable: React.FC<AccountsTableProps> = ({
                                                         accountName = null,
                                                         project = null,
                                                         service = null,
                                                         kaspiMoySkladRepricer = null,
                                                         kaspiMoySkladDopAccounts = null,
                                                         generalAccounts = null,
                                                         setGeneralAccounts,
                                                         setKaspiMoySkladDopAccounts,
                                                         setKaspiMoySkladRepricer,
                                                         onChange = () => {
                                                         }
                                                     }) => {
    const [editingProductLimitId, setEditingProductLimitId] = useState<number | null>(null);
    const [newProductLimit, setNewProductLimit] = useState<number | string>(0);
    const [hideAddButton, setHideAddButton] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);


    const {inverted, token} = useGlobalContext();
    const headers = {
        headers: {Authorization: `Bearer ${token}`}
    }

    const toastOptions: ToastOptions = {
        theme: inverted ? 'dark' : 'light',
        position: 'top-center'
    }

    useEffect(() => {
        setHideAddButton(false);
    },[accountName, project, service])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                setEditingProductLimitId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const update = async (id: null | number, date: null | string, productLimit?: number) => {
        if (!accountName) return;
        if (!productLimit && !date) return;
        if (!id && project === 'KASPI_MOYSKLAD' && service === 'REPRICER') return;

        setEditingProductLimitId(null);

        let url: string;
        if (project === 'KASPI_MOYSKLAD' && service) {
            url = `${process.env.REACT_APP_BACK_URL}/${accountName}/${project}/${service}`;
        } else if (project) {
            url = `${process.env.REACT_APP_BACK_URL}/${accountName}/${project}`;
        } else {
            return;
        }


        const data = productLimit ? {id, product_limit: productLimit} : {id, date};


        try {
            if (id)
                await axios.put<[]>(url, data, headers);
            else
                await axios.post<[]>(url, data, headers);
            toast.success("Обновлено", toastOptions);
            onChange();
            setHideAddButton(false);
        } catch (error) {
            toast.error("Ошибка", toastOptions);
            console.error(error);
        }

    }

    const handleAddDopAccount = () => {
        if (project === 'KASPI_MOYSKLAD' && service === 'REPRICER' && kaspiMoySkladRepricer) {
            setKaspiMoySkladRepricer(prev => prev ? [...prev, emptyKaspiMoySkladRepricer] : [emptyKaspiMoySkladRepricer]);
        } else if (project === 'KASPI_MOYSKLAD' && service === 'DOP_ACCOUNT' && kaspiMoySkladDopAccounts) {
            setKaspiMoySkladDopAccounts(prev => prev ? [...prev, emptyKaspiMoySkladDopAccounts] : [emptyKaspiMoySkladDopAccounts]);
        } else if (generalAccounts) {
            setGeneralAccounts(prev => prev ? [...prev, emptyGeneralAccount] : [emptyGeneralAccount]);
        }

        setHideAddButton(true);
    };


    const renderKaspiMoySkladRepricerTable = (data: KaspiMoySkladRepricer[]) => (
        <Table celled inverted={inverted}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Имя компании</Table.HeaderCell>
                    <Table.HeaderCell>ID компании</Table.HeaderCell>
                    <Table.HeaderCell>Дата окончания подписки</Table.HeaderCell>
                    <Table.HeaderCell>Лимит товаров</Table.HeaderCell>
                    <Table.HeaderCell>Телефон</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.map(account => (
                    <Table.Row key={account.kaspi_tokens_id}>
                        <Table.Cell>{account.company_name || '----'}</Table.Cell>
                        <Table.Cell>{account.company_id || '----'}</Table.Cell>
                        <Table.Cell>
                            <DatePickerModal date={account.data_end || null}
                                             onDateChange={(newDate: string) => update(account.kaspi_tokens_id, newDate)}/>
                        </Table.Cell>
                        <Table.Cell>
                            {editingProductLimitId === account.kaspi_tokens_id ? (
                                <input
                                    ref={inputRef}
                                    value={newProductLimit}
                                    onChange={(e) => {
                                        setNewProductLimit(e.target.value)
                                    }}
                                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                        if (e.key === 'Enter') {
                                            const value = Number(newProductLimit);
                                            if (isNaN(value)) return;
                                            update(account.kaspi_tokens_id, null, value);
                                        }
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <>
                                    <span>{account.product_limit}</span>
                                    <Icon
                                        name="edit"
                                        style={{marginLeft: '10px', cursor: 'pointer'}}
                                        onClick={() => {
                                            setEditingProductLimitId(account.kaspi_tokens_id);
                                            setNewProductLimit(account.product_limit);
                                        }}
                                    />
                                </>
                            )}
                        </Table.Cell>
                        <Table.Cell>{account.phone || '----'}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );

    const renderKaspiMoySkladDopAccountsTable = (data: KaspiMoySkladDopAccounts[]) => (
        <>

            <Table celled inverted={inverted}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Имя компании</Table.HeaderCell>
                        <Table.HeaderCell>ID компании</Table.HeaderCell>
                        <Table.HeaderCell>Дата окончания подписки</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map(account => (
                        <Table.Row key={account.id}>
                            <Table.Cell>{account.company_name || '----'}</Table.Cell>
                            <Table.Cell>{account.company_id || '----'}</Table.Cell>
                            <Table.Cell>
                                <DatePickerModal date={account.date_deactivate || null}
                                                 onDateChange={(newDate: string) => update(account.id, newDate)}/>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    );

    const renderGeneralAccountsTable = (data: GeneralAccount[]) => (
        <Table celled inverted={inverted}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Имя компании</Table.HeaderCell>
                    <Table.HeaderCell>Дата окончания подписки</Table.HeaderCell>
                    <Table.HeaderCell>Телефон</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.map(account => (
                    <Table.Row key={account.id}>
                        <Table.Cell>{account.name || '----'}</Table.Cell>
                        <Table.Cell>
                            <DatePickerModal date={account.date || null}
                                             onDateChange={(newDate: string) => update(account.id, newDate)}/>
                        </Table.Cell>
                        <Table.Cell>{account.phone || '----'}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );

    const renderTables = () => {
        if (project === 'KASPI_MOYSKLAD' && service === 'REPRICER' && kaspiMoySkladRepricer) {
            return renderKaspiMoySkladRepricerTable(kaspiMoySkladRepricer);
        } else if (project === 'KASPI_MOYSKLAD' && service === 'DOP_ACCOUNT' && kaspiMoySkladDopAccounts) {
            return renderKaspiMoySkladDopAccountsTable(kaspiMoySkladDopAccounts);
        } else if (generalAccounts) {
            return renderGeneralAccountsTable(generalAccounts);
        } else {
            return null;
        }
    }

    function handleRefreshButton() {
        onChange();
        setHideAddButton(false);
        toast.success("Обновлено", toastOptions);
    }

    return (
        <>
            {renderTables()}

            <div className='div-buttons-table'>
                <div className="placeholder">
                    {accountName && !hideAddButton && service !== 'REPRICER' && (
                        <Button inverted={inverted} onClick={handleAddDopAccount}>
                            Добавить доп. аккаунт
                        </Button>
                    )}
                </div>
                <Button icon='sync alternate' onClick={handleRefreshButton} inverted={inverted}></Button>
            </div>
        </>
    )

}

export default AccountsTable;
