import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {useGlobalContext} from "./GlobalProvider";
import {toast} from "react-toastify";

const Login: React.FC = () => {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {inverted, setToken, setUserName} = useGlobalContext();

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/login`, {
                name,
                password,
            });

            const { username, access_token } = response.data;
            setUserName(username);
            setToken(access_token);
            navigate('/');
        } catch (err) {
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                setError('Неверный логин или пароль');
            } else {
                const errorStatus =  axios.isAxiosError(error) ? (error.response?.status ?? '') : '';
                toast.error("Сервер недоступен " + errorStatus);
            }
        }
    };

    return (
        <div className='login-div'>
            <Form onSubmit={handleLogin} inverted={inverted} className='login-form'>
                <Form.Field >
                    <label>Пользователь</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Username"
                    />
                </Form.Field>
                <Form.Field>
                    <label>Пароль</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                </Form.Field>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <Button type="submit" primary inverted={inverted}>
                    Вход
                </Button>
            </Form>
        </div>
    );
};

export default Login;
