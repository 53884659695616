import {
    Container,
    Divider,
    Segment,
    Checkbox,
    Button,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
} from "semantic-ui-react";
import React, {SyntheticEvent, useCallback, useEffect, useMemo, useState} from "react";
import {useGlobalContext} from "./GlobalProvider";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";
import {GeneralAccount, KaspiMoySkladRepricer, KaspiMoySkladDopAccounts} from "../types";
import AccountsTable from "./AccountsTable";

const projectsOptions: DropdownItemProps[] = [
    {key: 'KASPI_MOYSKLAD', text: 'Kaspi + МойСклад', value: 'KASPI_MOYSKLAD'},
    {key: 'KASPI_AMOCCRM', text: 'Kaspi + AmoCrm', value: 'KASPI_AMOCCRM'},
    {key: 'WILDBERRIES_MOYSKLAD', text: 'Wildberries + МойСклад', value: 'WILDBERRIES_MOYSKLAD'},
    {key: 'SATU_MOYSKLAD', text: 'Satu + МойСклад', value: 'SATU_MOYSKLAD'},
];

const servicesOptions: DropdownItemProps[] = [
    {key: 'DOP_ACCOUNT', text: 'Дополнительный аккаунт', value: 'DOP_ACCOUNT'},
    {key: 'REPRICER', text: 'Репрайсер', value: 'REPRICER'},
];

const Main: React.FC = () => {
    const {inverted, setInverted, username, setUserName, setToken, token} = useGlobalContext();
    const navigate = useNavigate();
    const [project, setProject] = useState<null | string>(null);
    const [service, setService] = useState<null | string>(null);
    const [accountName, setAccountName] = useState<null | string>(null);
    const [searchOptions, setSearchOptions] = useState<DropdownItemProps[]>([]);
  //  const [searchText, setSearchText] = useState<string>('');
    const [kaspiMoySkladDopAccounts, setKaspiMoySkladDopAccounts] = useState<KaspiMoySkladDopAccounts[] | null>(null);
    const [kaspiMoySkladRepricer, setKaspiMoySkladRepricer] = useState<KaspiMoySkladRepricer[] | null>(null);
    const [generalAccounts, setGeneralAccounts] = useState<GeneralAccount[] | null>(null);

    const headers = useMemo(() => ({
        headers: {Authorization: `Bearer ${token}`}
    }), [token]);

    const fetchSearchOptions = async (text: string) => {
        try {
            const response = await axios.get<string[]>(`${process.env.REACT_APP_BACK_URL}/search/${text}/${project}`, headers);
            const options = response.data.map((item, index) => ({
                key: index,
                text: item,
                value: item,
            }));
            setSearchOptions(options);
        } catch (error) {
            console.error('Error fetching search options:', error);
        }
    };


    const handleSearchChange = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
     //   setSearchText(data.searchQuery || '');
        if (data.searchQuery) {
            fetchSearchOptions(data.searchQuery);
        }
    };



    const getTableData = useCallback( async (selectedAccountName?: string) => {
        const accountNameResult = selectedAccountName ? selectedAccountName : accountName;

        if (accountNameResult) {
            let url: string;
            if (project === 'KASPI_MOYSKLAD' && service) {
                url = `${process.env.REACT_APP_BACK_URL}/${accountNameResult}/${project}/${service}`;
            } else if (project) {
                url = `${process.env.REACT_APP_BACK_URL}/${accountNameResult}/${project}`;
            } else {
                return;
            }

            try {
                if (project === 'KASPI_MOYSKLAD') {
                    if (service === 'REPRICER') {
                        const response = await axios.get<KaspiMoySkladRepricer[]>(url, headers);

                        setKaspiMoySkladRepricer(response.data);
                        setGeneralAccounts(null);
                        setKaspiMoySkladDopAccounts(null);
                    } else if (service === 'DOP_ACCOUNT') {
                        const response = await axios.get<KaspiMoySkladDopAccounts[]>(url, headers);
                        setKaspiMoySkladDopAccounts(response.data);
                        setGeneralAccounts(null);
                        setKaspiMoySkladRepricer(null);
                    }
                } else {
                    const response = await axios.get<GeneralAccount[]>(url, headers);
                    setGeneralAccounts(response.data);
                    setKaspiMoySkladRepricer(null);
                    setKaspiMoySkladDopAccounts(null);
                }
            } catch (error) {
                console.error('Error fetching account details:', error);
            }
        }
    },  [headers, accountName, project, service, setGeneralAccounts, setKaspiMoySkladRepricer, setKaspiMoySkladDopAccounts]);


    const handleAccountNameChange = async (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const selectedAccountName = typeof data.value === 'string' ? data.value : null;
        setAccountName(selectedAccountName);
        // if (selectedAccountName)
        //     await getTableData(selectedAccountName);
    };

    const handleLogout = async () => {
        try {

            await axios.get(`${process.env.REACT_APP_BACK_URL}/logout`, headers);

        } catch (err) {
            toast.error("Произошла ошибка")
        } finally {
            setUserName('');
            setToken(null);
            navigate('/login');
        }
    };


    const clearTable = () => {
        setService(null);
        setAccountName(null);
        setSearchOptions([]);
        setKaspiMoySkladRepricer([]);
        setKaspiMoySkladDopAccounts([]);
        setGeneralAccounts([]);


    }

    useEffect(() => {
        console.log("AccountName in useEffect "  + accountName);
        getTableData();
    },[accountName, service])

    useEffect(() => {
        clearTable();
    },[project])



    return (
        <Container style={{paddingTop: '20px'}}>
            <Segment inverted={inverted}>
                <div className='header-main'>
                    <div style={{display: 'flex', alignItems: 'center' }}>
                        <Checkbox className={inverted ? 'dark' : ''} toggle
                                  onClick={() => setInverted(!inverted)}
                                  checked={inverted}></Checkbox>
                        <Button inverted={inverted} onClick={() => navigate('/logs')}>Логи</Button>
                    </div>
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <p className='username-style'>{username}</p>
                        <Button inverted={inverted} onClick={handleLogout}>Выйти</Button>
                    </div>
                </div>
                <Divider></Divider>
                <div>
                    <Dropdown
                        placeholder='Выберите проект'
                        selection
                        options={projectsOptions}
                        defaultValue={project ? project : ''}
                        onChange={(event: SyntheticEvent, data: DropdownProps) => {
                            setProject(typeof data.value === 'string' ? data.value : null);

                        }}
                    />
                    {project === 'KASPI_MOYSKLAD' && <Dropdown placeholder='Выберите сервис'
                                                               selection
                                                               options={servicesOptions}
                                                               defaultValue={service ? service : ''}
                                                               onChange={(event: SyntheticEvent, data: DropdownProps) => {
                                                                   setService(typeof data.value === 'string' ? data.value : null);

                                                               }}
                    />
                    }
                    {
                        ((project === 'KASPI_MOYSKLAD' && service) || (project && project !== 'KASPI_MOYSKLAD')) &&
                        (
                            <Dropdown
                                key={project}
                                placeholder="Введите название аккаунта"
                                search
                                selection

                                options={searchOptions}
                                onSearchChange={handleSearchChange}
                                onChange={handleAccountNameChange}
                            />
                        )
                    }
                </div>
                <Divider></Divider>
                <AccountsTable
                    accountName={accountName}
                    project={project}
                    service={service}
                    generalAccounts={generalAccounts}
                    kaspiMoySkladDopAccounts={kaspiMoySkladDopAccounts}
                    kaspiMoySkladRepricer={kaspiMoySkladRepricer}
                    setGeneralAccounts={setGeneralAccounts}
                    setKaspiMoySkladDopAccounts={setKaspiMoySkladDopAccounts}
                    setKaspiMoySkladRepricer={setKaspiMoySkladRepricer}
                    onChange={() => getTableData()}
                ></AccountsTable>
            </Segment>
        </Container>
    );
}

export default Main;