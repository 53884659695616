import React, { useState, useRef, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerModalProps {
    date: string|null;
    onDateChange: (newDate: string) => void;
}

const DatePickerModal: React.FC<DatePickerModalProps> = ({ date, onDateChange }) => {
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(date ? new Date(date): new Date());
    const datePickerRef = useRef<HTMLDivElement>(null);

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        if (date) onDateChange(date.toISOString().split('T')[0]);
        setOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    const formatDate = (date: string): string => {
        const dateObj = new Date(date);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // месяцы начинаются с 0
        const year = dateObj.getFullYear();
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <span>{date ? formatDate(date) : '----'}</span>
            <Icon name="calendar" style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setOpen(!open)} />
            {open && (
                <div ref={datePickerRef} style={{ position: 'absolute', zIndex: 1000 }}>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        inline
                    />
                </div>
            )}
        </div>
    );
};

export default DatePickerModal;
