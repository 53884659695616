import React, {useCallback, useEffect, useState} from 'react';
import {
    Table,
    Dropdown,
    Pagination,
    Container,
    Header,
    Button,
    Segment,
} from 'semantic-ui-react';
import axios from 'axios';
import 'semantic-ui-css/semantic.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useGlobalContext} from "./GlobalProvider";
import {format} from 'date-fns';
import {useNavigate} from "react-router-dom";

interface UserLog {
    id: number;
    user_name: string;
    event: string;
    project: string;
    service: string;
    account_mane: string;
    row_id: number;
    data_end_from: string;
    data_end_to: string;
    repricer_limit_from: string;
    repricer_limit_to: string;
    created_at: string;
}

type RowCountType = {
    project: string,
    service: string,
    count: number
}


interface PaginationMeta {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
}

const UsersLogTable: React.FC = () => {
    const {inverted, token} = useGlobalContext();
    const navigate = useNavigate();
    const [logs, setLogs] = useState<UserLog[]>([]);
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [meta, setMeta] = useState<PaginationMeta>({
        current_page: 1,
        last_page: 1,
        per_page: 10,
        total: 0,
    });




    const fetchLogs = useCallback(async (page: number, perPage: number, startDate: Date | null, endDate: Date | null) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/users-log`, {
                headers: {Authorization: `Bearer ${token}`},
                params: {
                    start_date: startDate ? format(startDate, 'dd-MM-yyyy') : null,
                    end_date: endDate ? format(endDate, 'dd-MM-yyyy') : null,
                    page: page,
                    per_page: perPage,
                },
            });
            setLogs(response.data.data);
            setMeta({
                current_page: response.data.current_page,
                last_page: response.data.last_page,
                per_page: response.data.per_page,
                total: response.data.total,
            });
        } catch (error) {
            console.error('Ошибка при загрузке данных логов:', error);
        }
    }, [token]);

    const handlePageChange = (_: any, data: any) => {
        setPage(data.activePage);
    };

    const handlePerPageChange = (_: any, data: any) => {
        setPerPage(data.value);
    };

    const perPageOptions = [
        {key: 10, value: 10, text: '10'},
        {key: 20, value: 20, text: '20'},
        {key: 50, value: 50, text: '50'},
        {key: 100, value: 100, text: '100'},
    ];

    const handleFilter = () => {
        setPage(1);
        fetchLogs(1, perPage, startDate, endDate);
    };

    useEffect(() => {
        fetchLogs(page, perPage, startDate, endDate);
    }, [page, perPage, startDate, endDate, fetchLogs]);

    const rowIdCounts: Map<number, RowCountType> = new Map();

    logs.forEach(log => {
        const existingRow = rowIdCounts.get(log.row_id);
        if (existingRow) {
            if (log.project === existingRow.project && log.service === existingRow.service) {
                existingRow.count++;
            }
        } else {
            rowIdCounts.set(log.row_id, {
                project: log.project,
                service: log.service,
                count: 1
            });
        }
    });

    // Определим row_id, которые повторяются
    const repeatedRowIds:number[] = [];
    rowIdCounts.forEach((value, key) => {
        if (value.count > 1) {
            repeatedRowIds.push(key)
        }
    })


    const repeatedColor = inverted ? '#232337' : '#bac3d7';


    return (
        <Container style={{marginTop: '20px'}}>
            <Segment inverted={inverted}>
                <p><Button inverted={inverted} onClick={() => navigate('/')}> &lt;&lt;&lt; На главную</Button></p>
                <Header inverted={inverted}>Логи</Header>

                <div className='header-main'>
                    <div style={{display: 'inline-grid'}}>
                        <DatePicker className='datepicker-log'
                                    selected={startDate}
                                    onChange={(date: Date | null) => setStartDate(date)}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Start Date"
                                    onSelect={() => {}}
                        />

                        <DatePicker className='datepicker-log'
                                    selected={endDate}
                                    onChange={(date: Date | null) => setEndDate(date)}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="End Date"
                                    onSelect={() => {}}

                        />
                    </div>

                    <Button onClick={handleFilter} icon='sync alternate'
                            style={{width: '50px', height: '40px'}}></Button>
                </div>

                <Table celled inverted={inverted}>
                    <Table.Header>
                        <Table.Row >
                            <Table.HeaderCell>Имя пользователя</Table.HeaderCell>
                            <Table.HeaderCell>Событие</Table.HeaderCell>
                            <Table.HeaderCell>Приложение</Table.HeaderCell>
                            <Table.HeaderCell>Сервис</Table.HeaderCell>
                            <Table.HeaderCell>На аккаунт</Table.HeaderCell>
                            <Table.HeaderCell>id записи в db</Table.HeaderCell>

                            <Table.HeaderCell style={{ width: '98px' }}>С даты</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '98px' }}>На дату</Table.HeaderCell>
                            <Table.HeaderCell>Лимит товаров с</Table.HeaderCell>
                            <Table.HeaderCell>Лимит товаров на</Table.HeaderCell>


                            <Table.HeaderCell>Дата выполнения</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {logs.map((log) => (
                            <Table.Row  key={log.id} style={{ backgroundColor: repeatedRowIds.includes(log.row_id) ? repeatedColor : 'inherit' }}>
                                <Table.Cell>{log.user_name}</Table.Cell>
                                <Table.Cell>{log.event}</Table.Cell>
                                <Table.Cell>{log.project}</Table.Cell>
                                <Table.Cell>{log.service || '----'}</Table.Cell>
                                <Table.Cell>{log.account_mane}</Table.Cell>
                                <Table.Cell>{log.row_id}</Table.Cell>


                                <Table.Cell>{log.data_end_from || '----'}</Table.Cell>
                                <Table.Cell>{log.data_end_to || '----'}</Table.Cell>
                                <Table.Cell>{log.repricer_limit_from || '----'}</Table.Cell>
                                <Table.Cell>{log.repricer_limit_to || '----'}</Table.Cell>


                                <Table.Cell>{log.created_at}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Pagination
                        activePage={meta.current_page}
                        totalPages={meta.last_page}
                        onPageChange={handlePageChange}
                    />
                    <div style={{display: 'flex', alignItems: 'center'}}>


                        <Dropdown style={{maxWidth: '20px'}}
                                  selection
                                  options={perPageOptions}
                                  value={perPage}
                                  onChange={handlePerPageChange}
                        />

                    </div>
                </div>

            </Segment>
        </Container>
    );
};

export default UsersLogTable;
