import React, { useEffect } from 'react';
import './App.scss';
import 'semantic-ui-css/semantic.min.css';
import Login from './components/Login';
import Main from './components/Main';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthCheck from './components/AuthCheck';
import { GlobalProvider, useGlobalContext } from './components/GlobalProvider';
import { ToastContainer } from 'react-toastify';
import UsersLogTable from "./components/UsersLogTable";

const App: React.FC = () => {
    return (
        <>

        <GlobalProvider>

            <InnerApp />
        </GlobalProvider>
            <ToastContainer />
        </>
    );
};

const InnerApp: React.FC = () => {
    const { inverted } = useGlobalContext();

    useEffect(() => {
        if (inverted) {
            document.body.style.backgroundColor = '#333';
        } else {
            document.body.style.backgroundColor = '#f0f0f0';
        }
    }, [inverted]);

    return (


            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/logs" element={<UsersLogTable />} />
                    <Route
                        path="/"
                        element={
                            <AuthCheck>
                                <Main />
                            </AuthCheck>
                        }
                    />
                </Routes>
            </Router>

    );
};

export default App;
